/** ***************************************************************************
 * Ecommerce / Templates / Homepage
 *************************************************************************** */

 @use "sass:map";
@use '~/src/styles/utilities.scss' as *;
@use "~/src/styles/shared/variables.module.scss" as *;

body {
  --cn-c-home--BackgroundColor: var(--cn-c-page--BackgroundColor);
  --cn-c-home--Color: var(--cn-c-page--Color);
  --cn-c-home--content--MaxWidth: #{map.get($grid-breakpoints, xxl)};
}

.cn-c-header > .main_nav-container {
  background-color: transparent;
  position: relative;
  z-index: 8;
}
.cn-c-header.fixed-top > .main_nav-container {
  background-color: var(--cn-c-home--BackgroundColor);
}
.cn-c-header.fixed-top .cn-c-main_nav {
  --cn-c-main_nav--BackgroundColor: var(--cn-c-page--BackgroundColor);
  --cn-c-main_nav--Color: var(--cn-c-page--Color);

  .cn-c-label {
    color: unset;
  }
}

.cn-c-main_nav {
  --cn-c-main_nav--BackgroundColor: transparent;
  --cn-c-main_nav--Color: var(--cn-c-page--BackgroundColor);

  .cn-c-label {
    color: var(--cn-c-page--BackgroundColor);
  }
}

.cn-c-products_slider {
  padding-inline: var(--cn-global--content-grid--offsetsize);

  @include dispatchToContextBreakpoints($grid-breakpoints) {
    margin-block-start: calc(-1 * var(--cn-c-template_shared--main_nav--Height));
  }
}

.splide {
  display: grid;
  grid-template-columns: var(--cn-c-page--Grid-Template);
  grid-gap: 0 var(--cn-c-page--Grid-Gap);
  width: 100%;

  .splide__track {
    margin-inline: calc(-1* var(--cn-global--content-grid--offsetsize));
  }
}

.home-heading {
  display: grid;
  grid-template-columns: var(--cn-c-page--Grid-Template);
  grid-gap: 0 var(--cn-c-page--Grid-Gap);
  align-items: baseline;
  padding-block: pxToRem(49px);
  padding-inline: var(--cn-global--content-grid--offsetsize);
  max-width: var(--cn-c-home--content--MaxWidth, var(--cn-c-template_shared--content--MaxWidth));
  margin-inline: auto;
  background-color: var(--cn-c-page--BackgroundColor);

  .start {
    grid-column: 1 / -1;

    h2 {
      margin-block-end: pxToRem(40px);
    }

    .cn-c-header_heading {
      padding-inline-start: unset;
      display: flex;
      gap: pxToRem(7px);
    }

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      grid-column: 1 / span 7;
    }

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      grid-column: 2 / span 6;
    }
  }

  .end {
    grid-column: 1 / -1;

    .cn-c-label {
      margin-block-start: pxToRem(70px);
    }

    > div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      column-gap: pxToRem(16px);
      row-gap: pxToRem(9px);
      margin-block-start: pxToRem(14px);
      max-width: 400px;
    }

    @media screen and (min-width: map.get($grid-breakpoints, md)) {
      grid-column: 9 / span 4;

      .cn-c-label {
        margin-block-start: unset;
      }
    }
  }

  @media screen and (min-width: map.get($grid-breakpoints, lg)) {
    padding-block: pxToRem(128px) pxToRem(99px);
  }
}

main {
  flex: 1;
  display: grid;
  grid-template-columns: var(--cn-c-page--Grid-Template);
  grid-gap: 0 var(--cn-c-page--Grid-Gap);
}

.catalog_section-wrapper {
  background-color: var(--cn-global--palette-white-smoke);
}

.cn-c-catalog_section {
  padding-block: pxToRem(64px) pxToRem(59px);
  max-width: var(--cn-c-home--content--MaxWidth, var(--cn-c-template_shared--content--MaxWidth));
  margin-inline: auto;
  width: 100%;

  > * {
    padding-inline: var(--cn-global--content-grid--offsetsize);

    max-width: var(--cn-c-home--content--MaxWidth, var(--cn-c-template_shared--content--MaxWidth));
    margin-inline: auto;
  }
}

.list-wrapper {
  grid-column: -1 / 1;
  width: 100%;
  display: grid;
  // grid-template-columns: 0 1fr;
  transition: var(--cn-global--Duration--sm);

  @media screen and (min-width: map.get($grid-breakpoints, lg)) {
    grid-template-columns: 1fr;

    &:has(.cn-c-filters_panel) {
      grid-template-columns: 0 1fr;
    }
  }

  &.is-open {
    // grid-template-columns: 33% 1fr;
    grid-gap: 0 var(--cn-c-page--Grid-Gap);

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      grid-template-columns: 31.4% 1fr;
    }

    @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
      grid-template-columns: 22.7% 1fr;
    }
  }
}
.list-wrapper.is-open {

  > .cn-c-filters_panel {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  > .cn-c-product_list {

    @media screen and (min-width: map.get($grid-breakpoints, lg)) {
      // grid-column: 5 / -1;

      .cn-c-product_card {
        grid-column: span 6;
      }
    }

    @media screen and (min-width: map.get($grid-breakpoints, xxl)) {
      // grid-column: 4 / -1;

      .cn-c-product_card {
        grid-column: span 4;
      }
    }

  }
}

.cn-c-catalog_cta {
  display: grid;
  grid-template-columns: var(--cn-c-page--Grid-Template);
  grid-gap: 0 var(--cn-c-page--Grid-Gap);
  width: 100%;

  > .container {
    max-width: var(--cn-c-home--content--MaxWidth, var(--cn-c-template_shared--content--MaxWidth));
    margin-inline: auto;
  }
}

.cn-c-channels {
  padding-block: pxToRem(50px);
  padding-inline: var(--cn-global--content-grid--offsetsize);
  max-width: var(--cn-c-home--content--MaxWidth, var(--cn-c-template_shared--content--MaxWidth));
  margin-inline: auto;

  @media screen and (min-width: map.get($grid-breakpoints, md)) {
    padding-block: pxToRem(93px) pxToRem(86px);
  }
}
